'use strict';

import 'popper.js';
import 'bootstrap/js/dist/dropdown';
import './components/common';
import './components/form';
import './components/flyout';
import './components/responsiveBackground';
import './components/lazyload';
import './components/cookie';

//  Icon font generation - do not remove
import '../icons/iconfont.font';
