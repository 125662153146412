'use strict';

import $ from 'jquery';
import { debounce, isBreakpointActive } from './helpers';
require('modaal/dist/js/modaal');

window.app = window.app || {};
window.app.variables = window.app.variables || {};

const $document = $(document);
const $window = $(window);
const $html = $('html');
const $body = $('body');

windowResize();

$window.on('resize', debounce(windowResize, 250, false));

$window.on('load', jsDone);

setTimeout(jsDone, 4000);

$('.js-notice-close').on('click', function (e) {

    e.preventDefault();

    const $notice = $(this).closest('.notice');

    $notice.slideToggle(250, () => {
        $notice.remove();
    });
});

function windowResize() {

    const isFlyoutActive = isBreakpointActive('flyout');
    const windowWidth = $window.width();
    const windowHeight = $window.height();

    if (!isFlyoutActive) {
        $body.removeClass('flyout-active');
    }

    $.extend(window.app.variables, {
        isFlyoutActive,
        windowWidth,
        windowHeight
    });
}

function jsDone() {
    $html.addClass('js-done');
}


// language dropdown
$('.dropdown-toggle').dropdown();


// age checker * - - > zie cookie.js
/*$('.js-agecheck').on('click', 'button[type="submit"]', function (e) {

    e.preventDefault();

    const $form = $(this.form);

    if ($form.parsley().validate({ force: true })) {

        localStorage.setItem('isUnderAge', false);
        $('.js-modal-agecheck').modaal('close');
    }

    return false;
});

if (!localStorage.getItem('isUnderAge') || localStorage.getItem('isUnderAge') === 'true') {
    $('.js-modal-agecheck').modaal({
        content_source: '#modal-agecheck',
        start_open: true,
        is_locked: true
    });
}*/
