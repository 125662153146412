import $ from 'jquery';

const config = {
    rootMargin: '0px 0px 50px 0px',
    threshold: 0
};


// lazy load instagram juicer feed

var juicer = document.getElementById('juicer');

function watchJuicer() {
    if (juicer) {
        const observeJuicer = new IntersectionObserver(function(entries, self) {
            entries.forEach(entry => {

                if (entry.isIntersecting) {
                    self.unobserve(juicer);
                    loadJuicer();

                }
            });
        }, config);

        observeJuicer.observe(juicer);
    }
}

function loadJuicer() {
    $.getScript('//assets.juicer.io/embed.js');
    $('head').append('<link rel="stylesheet" type="text/css" href="//assets.juicer.io/embed.css">');
}




// IE support
// If we don't have support for intersection observer, load the images immediately

if ('IntersectionObserver' in window) {
    watchJuicer();
} else {
    loadJuicer();
}
